<template>
  <div class="temporary-container">
    <ContentTitle>{{ tableTitle }}</ContentTitle>
    <Wfilter
      @handleSearch="handleSearch"
      @changeStatus="changeStatus"
      @changeProject="changeProject"
      :filterConfig="filterConfig"
      @handleSearchTime="handleSearchTime"
      @print="handlePrint"
      @export="handleExport"
    ></Wfilter>
    <Wtable
      :columns="columns"
      :pagination="pagination"
      :tableData="tableData"
      @info="openDetailDialog"
      @currentChange="currentChange"
      @sizeChange="sizeChange"
    >
      <template #status="{ row }">
        <span v-if="row.status == 1" style="color: var(--theme-color)">{{
          row.status_txt
        }}</span>
        <span v-else-if="row.status == -2" style="color: var(--error-color)">{{
          row.status_txt
        }}</span>
        <span
          v-else-if="row.status == -1"
          style="color: var(--text-third-color)"
          >{{ row.status_txt }}</span
        >
        <span v-else>{{ row.status_txt }}</span>
      </template>
      <div class="total-money" v-if="tableData.length">
        费用合计：<span class="primary">{{ sumMoney }}元</span>
      </div>
    </Wtable>
    <Wdialog
      ref="detailDialog"
      title="通行记录"
      width="60%"
      :showAction="false"
    >
      <Wtable
        :columns="detailColumns"
        :pagination="detailPagination"
        :tableData="detailTableData"
        @currentChange="detailCurrentChange"
        @sizeChange="detailSizeChange"
      ></Wtable>
    </Wdialog>
  </div>
</template>
<script>
import { ref, onMounted, reactive, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import { print, exportExcel, getBtnAuth } from "@/utils/common.js";
import { ElMessage } from "element-plus";
import Wtable from "@/components/wTable/wTable.vue";
import Wdialog from "@/components/wDialog/wDialog.vue";
import Wfilter from "@/components/wFilter/wFilter.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import config from "./temporaryRecord.json";
import API from "@/plugins/api.js";

export default {
  components: {
    Wtable,
    Wdialog,
    Wfilter,
    ContentTitle,
  },
  setup() {
    const store = useStore();
    const filterConfig = reactive(config.filterConfig); //筛选配置
    const defaultBtns = reactive(config.tableConfig.btns);
    const columns = reactive(config.tableConfig.list); //表格配置
    const rightBtn = computed(() => store.state.saasConfig.rightBtn); //右侧页面的所有权限信息
    columns[columns.length - 1].btns = getBtnAuth(rightBtn.value, defaultBtns); // 获取有权限查看或操作的表格列

    const pagination = config.tableConfig.pagination; //分页配置
    const tableData = ref([]); // 表格数据
    const sumMoney = ref(""); // 表格下方展示的费用总计
    const keywords = ref(""); // 搜索的关键字
    const projectId = ref(""); // 筛选的项目id
    const dateArr = ref([]); // 筛选的起始日期
    const page = ref(""); // 表格的当前页
    const status = ref(null); // 筛选的状态
    const tableTitle = ref("临停收费记录"); // 表格标题
    // 获取表格数据
    function fetchData(parmas) {
      let lastParmas = {
        ...parmas,
        keywords: keywords.value,
        project_id: projectId.value,
        s_time:
          dateArr.value.length && dateArr.value[0] ? dateArr.value[0] : "",
        e_time:
          dateArr.value.length && dateArr.value[1] ? dateArr.value[1] : "",
        page: page.value,
        status: status.value,
        limit: pagination.pageSize,
      };
      API.getTemporaryRecord(lastParmas).then((res) => {
        pagination.total = res.total;
        tableData.value = res.data;
        sumMoney.value = res.sum_money;
      });
    }

    onMounted(() => {
      // 获取项目选择器的数据
      API.getProjectOptions({}).then((res) => {
        if (res.length) {
          filterConfig.selects[0].mapData = res;
        }
      });
      // 获取状态选择器的数据
      API.getStatusOptions({}).then((res) => {
        if (res.length) {
          filterConfig.selects[1].mapData = res;
        }
      });
      fetchData();
    });
    // 页码变化
    const currentChange = (v) => {
      page.value = v;
      fetchData();
    };
    // 每页显示多少条数据
    const sizeChange = (v) => {
      pagination.pageSize = v;
      fetchData();
    };
    // 搜索关键字
    const handleSearch = (kwd) => {
      keywords.value = kwd;
      fetchData();
    };
    // 筛选项目
    const changeProject = (pro) => {
      projectId.value = pro.value;
      fetchData();
    };
    // 筛选状态
    const changeStatus = (pro) => {
      status.value = pro.value;
      fetchData();
    };
    //  筛选时间
    const handleSearchTime = (data) => {
      dateArr.value = data;
      fetchData();
    };
    const detailDialog = ref(null); //详情-通行记录弹窗
    const detailPagination = config.detailTableConfig.pagination; // 详情-通行记录分页配置
    const detailColumns = config.detailTableConfig.list; // 详情-通行记录表格配置
    const detailTableData = ref([]); // 详情-通行记录数据
    const orderId = ref(""); // 订单号
    const detailPage = ref("");
    // 获取详情-通行记录数据
    function getDetailData(parmas) {
      const lastParmas = {
        ...parmas,
        order_id: orderId.value,
        page: detailPage.value,
      };
      API.getTrafficRecord(lastParmas).then((res) => {
        detailTableData.value = res.data;
        detailPagination.total = res.total;
        detailDialog.value.show();
      });
    }
    // 打开详情-通行记录弹窗
    async function openDetailDialog(data) {
      orderId.value = await data.row.id;
      getDetailData();
    }
    // 详情-通行记录-页码变化
    const detailCurrentChange = (v) => {
      detailPage.value = v;
      getDetailData();
    };
    // 详情-通行记录-每页显示多少条数据
    const detailSizeChange = (v) => {
      detailPagination.pageSize = v;
      getDetailData();
    };
    // 需要打印和导出的数据
    const propertiesConfig = ref([
      { field: "p_name", displayName: "项目名称" },
      { field: "cp_name", displayName: "车场名称" },
      { field: "gt_name", displayName: "交费入口" },
      { field: "allmoney", displayName: "交费金额" },
      { field: "order_sn", displayName: "业务订单号" },
      { field: "mch_ordernum", displayName: "商户订单号" },
      { field: "pay_way", displayName: "支付方式" },
      { field: "create_time", displayName: "交费时间" },
      { field: "status_txt", displayName: "订单状态" },
    ]);
    // 打印
    function handlePrint() {
      print(tableTitle.value, tableData.value, propertiesConfig.value);
    }
    // 导出
    function handleExport() {
      try {
        exportExcel(tableTitle.value, tableData.value, propertiesConfig.value);
        ElMessage.success({
          message: "导出成功！",
        });
      } catch (e) {
        ElMessage.error({
          message: "导出失败！",
        });
      }
    }
    // 离开页面 确保选择框的值清空
    onUnmounted(() => {
      filterConfig.selects[0].value = "";
      filterConfig.selects[1].value = "";
    });
    return {
      tableData,
      columns,
      pagination,
      currentChange,
      detailDialog,
      openDetailDialog,
      filterConfig,
      changeProject,
      changeStatus,
      handleSearch,
      detailColumns,
      detailTableData,
      handleSearchTime,
      sizeChange,
      sumMoney,
      detailPagination,
      detailCurrentChange,
      detailSizeChange,
      handlePrint,
      handleExport,
      tableTitle,
    };
  },
};
</script>

<style lang='scss'>
.temporary-container {
}
</style>